import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import styled from "styled-components";
import theme from "../theme/theme.json";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Button from "../components/Button";
import CurrentHons from "../components/site/CurrentHons";
import CurrentMasters from "../components/site/CurrentMasters";
import CurrentPHD from "../components/site/CurrentPHD";
import CurrentOther from "../components/site/CurrentOther";

const CurrentStudents = () => {
  const [showLevel, setShowLevel] = useState("Hon");
  return (
    <Layout>
      <SEO
        title="Current Students"
        description="The Adrift Lab team is comprised of students from all over the world from a range of very diverse backgrounds."
        slug="/current-students"
      />
      <Container mt="60px" mb="20px">
        <Owl>
          <h1>Current Students</h1>
          <p>
            The Adrift Lab team is comprised of students from all over the world
            from a range of very diverse backgrounds. We value the unique
            insights, skills, and perspectives each one of our students and
            collaborators brings to our team.
          </p>
          <ButtonContainer>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "Hon" ? "outline" : "solid"}
              onClick={() => setShowLevel("Hon")}
            >
              Honours
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "MsC" ? "outline" : "solid"}
              onClick={() => setShowLevel("MsC")}
            >
              Masters
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "PhD" ? "outline" : "solid"}
              onClick={() => setShowLevel("PhD")}
            >
              PhD
            </Button>
            <Button
              fs={theme.fs.sm}
              display="block"
              style={showLevel == "Other" ? "outline" : "solid"}
              onClick={() => setShowLevel("Other")}
            >
              Other
            </Button>
          </ButtonContainer>
        </Owl>
      </Container>
      <Container width="1024px" mb="50px">
        <div style={{ height: 20 }} />
        {showLevel === "Hon" && (
          <CardGrid>
            <CurrentHons />
          </CardGrid>
        )}
        {showLevel === "MsC" && (
          <CardGrid>
            <CurrentMasters />
          </CardGrid>
        )}
        {showLevel === "PhD" && (
          <CardGrid>
            <CurrentPHD />
          </CardGrid>
        )}
        {showLevel === "Other" && (
          <CardGrid>
            <CurrentOther />
          </CardGrid>
        )}
      </Container>
    </Layout>
  );
};

export default CurrentStudents;

const CardGrid = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-auto-rows: 1fr;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-top: 0;
  }
`;
